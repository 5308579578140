.slj-menu-container {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 999;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}

.slj-menu-item {
  border-bottom: 1px solid rgba(0, 0, 0, .3);
  position: relative;
  color: #000;
  cursor: pointer;
  padding: 12px 16px;
  background: #fff;
  white-space: nowrap;
}

.slj-menu-item:hover {
  background: #1C244E;
  color: #fff;
}

.slj-menu-item:last-child {
  border-bottom: 0;
}

.slj-menu-container li:hover > .slj-submenu-first {
  display: flex;
}

.slj-submenu-first li:hover > .slj-submenu-second {
  display: flex;
}

.slj-submenu-first {
  position: absolute;
  top: -1px;
  left: 100%;
  display: none;
  flex-direction: column;
  background: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}

.slj-submenu-second {
  position: absolute;
  top: -1px;
  left: 100%;
  display: none;
  flex-direction: column;
  background: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}
