@-webkit-keyframes entering {
    from {
        -webkit-transform: translate3d(-50%, -100%, 0);
                transform: translate3d(-50%, -100%, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate3d(-50%, 70px, 0);
                transform: translate3d(-50%, 70px, 0);
        opacity: 1;
    }
}

@keyframes entering {
    from {
        -webkit-transform: translate3d(-50%, -100%, 0);
                transform: translate3d(-50%, -100%, 0);
        opacity: 0;
    }
    to {
        -webkit-transform: translate3d(-50%, 70px, 0);
                transform: translate3d(-50%, 70px, 0);
        opacity: 1;
    }
}

@-webkit-keyframes leaving {
    from {
        -webkit-transform: translate3d(-50%, 70px, 0);
                transform: translate3d(-50%, 70px, 0);
        opacity: 1;
    }
    to {
        -webkit-transform: translate3d(-50%, -100%, 0);
                transform: translate3d(-50%, -100%, 0);
        opacity: 0;
    }
}

@keyframes leaving {
    from {
        -webkit-transform: translate3d(-50%, 70px, 0);
                transform: translate3d(-50%, 70px, 0);
        opacity: 1;
    }
    to {
        -webkit-transform: translate3d(-50%, -100%, 0);
                transform: translate3d(-50%, -100%, 0);
        opacity: 0;
    }
}

.zhj-notice {
    display: flex;
    justify-content: center;
    align-items: center;
    /*border: 1px solid;*/
    padding: 1em;
    border-radius: 0.5em !important;
    /*min-width: 300px;
    max-width: 500px;*/

    position: fixed;
    left: 50%;
    -webkit-transform: translate3d(-50%, -100%, 0);
            transform: translate3d(-50%, -100%, 0);
    z-index: 9999;
    top: 0;
    opacity: 0;
    font-size: 14px;

    -webkit-animation: .3s entering;

            animation: .3s entering;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;

    /*border-color:#ebeef5;*/
    /*background-color:#edf2fc;*/
}

/* .zhj-notice > svg {
    font-size: 18px;
}
.zhj-notice-content {
    padding-left: .5em;
    line-height: 1.3em;
} */
/*.zhj-notice-content {*/
    /*padding-left: 1em;*/
    /*line-height: 1.3em;*/
/*}*/

.zhj-notice.closing {
    -webkit-animation: leaving .3s;
            animation: leaving .3s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
}

/*.zhj-notice.info {*/
    /*color:#909399;*/
/*}*/
/*.zhj-notice.success {*/
    /*background-color:#f0f9eb;*/
    /*border-color:#e1f3d8;*/
    /*color:#67c23a;*/
/*}*/
/*.zhj-notice.warning {*/
    /*background-color:#fdf6ec;*/
    /*border-color:#faecd8;*/
    /*color:#e6a23c;*/
/*}*/
/*.zhj-notice.error {*/
    /*background-color:#fef0f0;*/
    /*border-color:#fde2e2;*/
    /*color:#f56c6c*/
/*}*/

.slj-menu-container {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 999;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}

.slj-menu-item {
  border-bottom: 1px solid rgba(0, 0, 0, .3);
  position: relative;
  color: #000;
  cursor: pointer;
  padding: 12px 16px;
  background: #fff;
  white-space: nowrap;
}

.slj-menu-item:hover {
  background: #1C244E;
  color: #fff;
}

.slj-menu-item:last-child {
  border-bottom: 0;
}

.slj-menu-container li:hover > .slj-submenu-first {
  display: flex;
}

.slj-submenu-first li:hover > .slj-submenu-second {
  display: flex;
}

.slj-submenu-first {
  position: absolute;
  top: -1px;
  left: 100%;
  display: none;
  flex-direction: column;
  background: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}

.slj-submenu-second {
  position: absolute;
  top: -1px;
  left: 100%;
  display: none;
  flex-direction: column;
  background: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
}

.LoadingContainer {
  width:100%;
  height:100%;
  background: #e6e6e6;
  opacity: .8;
}

.loadingPointGroup {
  position: relative;
  top: 50%;
  left: 50%;
}

.loading-point {
  display:inline-block;
  margin-right: 5px;
  height:10px;
  width:10px;
  opacity:0;
  border-radius:50%;
  -webkit-transform:translateX(-300px);
          transform:translateX(-300px);
  background-color:#4b9cdb
}

.loading-point-1 {
  -webkit-animation:k-loadingP 2s infinite;
          animation:k-loadingP 2s infinite
}
.loading-point-2 {
  -webkit-animation:k-loadingP 2s infinite;
          animation:k-loadingP 2s infinite;
  -webkit-animation-delay:.15s;
          animation-delay:.15s
}
.loading-point-3 {
  -webkit-animation:k-loadingP 2s infinite;
          animation:k-loadingP 2s infinite;
  -webkit-animation-delay:.30s;
          animation-delay:.30s
}
.loading-point-4 {
  -webkit-animation:k-loadingP 2s infinite;
          animation:k-loadingP 2s infinite;
  -webkit-animation-delay:.45s;
          animation-delay:.45s
}
.loading-point-5 {
  -webkit-animation:k-loadingP 2s infinite;
          animation:k-loadingP 2s infinite;
  -webkit-animation-delay:.60s;
          animation-delay:.60s
}

@-webkit-keyframes k-loadingP {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0)
  }
  35% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: .3
  }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: .8
  }
  70% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
    opacity: .8
  }
  85% {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px)
  }
}

@keyframes k-loadingP {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0)
  }
  35% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: .3
  }
  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: .8
  }
  70% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
    opacity: .8
  }
  85% {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px)
  }
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  height: 100vh;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
#root {
  height: 100%;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.87); }

span {
  line-height: 1.3; }

.rdt input {
  width: 100%;
  height: 30px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23); }

.rdtPicker {
  display: none;
  position: absolute;
  left: -10px;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9; }

.rdtOpen .rdtPicker {
  display: block; }

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

.rdtPicker .rdtTimeToggle {
  text-align: center; }

.rdtPicker table {
  width: 100%;
  margin: 0; }

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px; }

.rdtPicker td {
  cursor: pointer; }

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer; }

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999; }

.rdtPicker td.rdtToday {
  position: relative; }

.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px; }

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff; }

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker td span.rdtOld {
  color: #999999; }

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker th {
  border-bottom: 1px solid #f9f9f9; }

.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default; }

.rdtPicker th.rdtSwitch {
  width: 100px; }

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top; }

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  /* Firefox */
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker thead tr:first-child th {
  cursor: pointer; }

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee; }

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9; }

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

.rdtPicker button:hover {
  background-color: #eee; }

.rdtPicker thead button {
  width: 100%;
  height: 100%; }

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee; }

.rdtCounters {
  display: inline-block; }

.rdtCounters > div {
  float: left; }

.rdtCounter {
  height: 100px; }

.rdtCounter {
  width: 40px; }

.rdtCounterSeparator {
  line-height: 100px; }

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  /* Firefox */
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtCounter .rdtBtn:hover {
  background: #eee; }

.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px; }

.rdtTime td {
  cursor: default; }

