@keyframes entering {
    from {
        transform: translate3d(-50%, -100%, 0);
        opacity: 0;
    }
    to {
        transform: translate3d(-50%, 70px, 0);
        opacity: 1;
    }
}

@keyframes leaving {
    from {
        transform: translate3d(-50%, 70px, 0);
        opacity: 1;
    }
    to {
        transform: translate3d(-50%, -100%, 0);
        opacity: 0;
    }
}

.zhj-notice {
    display: flex;
    justify-content: center;
    align-items: center;
    /*border: 1px solid;*/
    padding: 1em;
    border-radius: 0.5em !important;
    /*min-width: 300px;
    max-width: 500px;*/

    position: fixed;
    left: 50%;
    transform: translate3d(-50%, -100%, 0);
    z-index: 9999;
    top: 0;
    opacity: 0;
    font-size: 14px;

    animation: .3s entering;
    animation-fill-mode: forwards;

    /*border-color:#ebeef5;*/
    /*background-color:#edf2fc;*/
}

/* .zhj-notice > svg {
    font-size: 18px;
}
.zhj-notice-content {
    padding-left: .5em;
    line-height: 1.3em;
} */
/*.zhj-notice-content {*/
    /*padding-left: 1em;*/
    /*line-height: 1.3em;*/
/*}*/

.zhj-notice.closing {
    animation: leaving .3s;
    animation-fill-mode: backwards;
}

/*.zhj-notice.info {*/
    /*color:#909399;*/
/*}*/
/*.zhj-notice.success {*/
    /*background-color:#f0f9eb;*/
    /*border-color:#e1f3d8;*/
    /*color:#67c23a;*/
/*}*/
/*.zhj-notice.warning {*/
    /*background-color:#fdf6ec;*/
    /*border-color:#faecd8;*/
    /*color:#e6a23c;*/
/*}*/
/*.zhj-notice.error {*/
    /*background-color:#fef0f0;*/
    /*border-color:#fde2e2;*/
    /*color:#f56c6c*/
/*}*/
